import * as React from "react"
import { useState } from "react"
import { ContactUsIcon } from "../components/svg"
import { Seo, Layout, Section, Grid, Container, Col } from "../components"

// import emailjs from "emailjs-com"
import _keys from "lodash/keys"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function Contact() {
  const [name, setName] = useState("")
  // const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [zip, setZip] = useState("")
  const [state, setState] = useState("")
  const [message, setMessage] = useState("")
  const [contactReason, setContactReason] = useState("")
  const [tracking, setTracking] = useState("")
  const [showError, setShowError] = useState(false)
  const notify = () => toast("Your inquiry has been submitted successfully!")
  function clearFields() {
    setName("")
    setEmail("")
    // setTitle("")
    setPhoneNumber("")
    setAddress("")
    setCity("")
    setState("")
    setZip("")
    setContactReason("")
    setMessage("")
    setTracking("")
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (name === "") {
      setShowError(true)
      return false
    }
    if (email === "") {
      setShowError(true)
      return false
    }
    if (!(email.indexOf("@") > 0)) {
      setShowError(true)
      return false
    }
    if (
      email.charAt(email.length - 4) !== "." &&
      email.charAt(email.length - 3) !== "."
    ) {
      setShowError(true)
      return false
    }
    // if (title === "") {
    //   setShowError(true)
    //   return false
    // }
    if (phoneNumber === "") {
      setShowError(true)
      return false
    }
    if (address === "") {
      setShowError(true)
      return false
    }
    if (city === "") {
      setShowError(true)
      return false
    }
    if (state === "") {
      setShowError(true)
      return false
    }
    if (zip === "") {
      setShowError(true)
      return false
    }
    if (contactReason === "") {
      setShowError(true)
      return false
    }
    if (message === "") {
      setShowError(true)
      return false
    }
    if (FIELD_TYPE === "") {
      setShowError(true)
      return false
    }

    console.log("value", {
      name,
      email,
      // title,
      phoneNumber,
      address,
      city,
      state,
      zip,
      contactReason,
      message,
      tracking,
      FIELD_TYPE,
    })
    let myData = {
      name,
      email,
      // title,
      phoneNumber,
      address,
      city,
      state,
      zip,
      contactReason,
      message,
      trackingNumber: tracking,
      FIELD_TYPE,
    }

    fetch("https://api.shipx.com/internal/contacts/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": "TNo7IHqnWLaMV3jyqCgSdarwt9Rib2R69DPsLER6",
      },
      body: JSON.stringify(myData),
    }).then(result => {
      // console.warn(result);
      result.json().then(resp => {
        console.warn("resp", resp)
      })
    })

    clearFields()
    notify()
  }

  const FIELD_TYPE = {
    show_job: "New Business Inquiry",
    show_track: "Delivery Issue",
    show_media: "Media Inquiry",
    other: "Other",
  }

  const FIELD_TYPE_LIST = _keys(FIELD_TYPE)

  // const showjobtitle = () => {
  //   return (
  //     <>
  //       <input
  //         className="border-2 pl-4 rounded-3xl placeholder-black h-12 w-full"
  //         type="text"
  //         name="job-title"
  //         placeholder="Job Title"
  //         value={title}
  //         onChange={e => {
  //           setTitle(e.target.value)
  //         }}
  //         required
  //       />
  //       {showError && (
  //         <div className="text-xs pl-4 text-red-500">Can't be left Empty</div>
  //       )}
  //     </>
  //   )
  // }

  // const showtrackingtitle = () => {
  //   return (
  //     <>
  //       <input
  //         className="border-2 pl-4 rounded-3xl placeholder-black h-12 w-full"
  //         type="text"
  //         name="tracking"
  //         placeholder="Tracking Number"
  //         value={tracking}
  //         onChange={e => {
  //           setTracking(e.target.value)
  //         }}
  //         required
  //       />
  //       {showError && (
  //         <div className="text-xs pl-4 text-red-500">Can't be left Empty</div>
  //       )}
  //     </>
  //   )
  // }

  // const renderField = () => {
  //   switch (contactReason) {
  //     case FIELD_TYPE.show_job:
  //       return showjobtitle()
  //     case FIELD_TYPE.show_track:
  //       return showtrackingtitle()
  //     case FIELD_TYPE.show_media:
  //       return showjobtitle()
  //     case FIELD_TYPE.other:
  //       return showjobtitle()
  //     default:
  //       return showjobtitle()
  //   }
  // }

  return (
    <Layout>
      <Seo
        title="Let's Solve Your Final Mile Delivery Headaches Today"
        description="ShipX is at your service! Looking for your package or a final mile shipping solution? We're here for you! Contact us today to get started."
        slug="contact"
      />
      <Section padding="none">
        <Container
          padding="global-md"
          classes="bg-white mx-auto lg:mt-40 sm:mt-20 py-8 px-4 lg:p-12 border-2 mb-8"
        >
          <div>
            <Grid classes="sm:grid-cols-12 justify-center mb-6 gap-10">
              <Col classes="order-1 col-span-1 sm:col-span-3 mx-auto">
                <ContactUsIcon className="w-32 h-32" />
              </Col>
              <Col classes="order-2 col-span-1 sm:col-span-9 mx-auto">
                <h1 className="font-bold mb-2">Get in touch</h1>
                <p className="">
                  ShipX is at your service! Whether you're looking for package
                  or you're a potential client that needs a final mile shipping
                  solution, we're here for you! Please fill out the form below,
                  and we'll be in touch within 24 hours, usually less!
                </p>
              </Col>
            </Grid>
            <div className="">
              <form className="sm:pt-5" onSubmit={handleSubmit}>
                <Grid classes="lg:grid-cols-2 gap-3">
                  <Col classes="lg:col-span-2">
                    <select
                      className="border-2 rounded-3xl placeholder-black h-12 w-full px-4 bg-white sm:w-full"
                      value={contactReason}
                      name="list-drop-down"
                      onChange={e =>
                        setContactReason(
                          e.target.value === "Reason for getting in touch?"
                            ? undefined
                            : e.target.value
                        )
                      }
                    >
                      <option value={undefined} className="form-control">
                        Reason for getting in touch?
                      </option>
                      {FIELD_TYPE_LIST.map(connector => {
                        return (
                          <option
                            key={connector}
                            value={FIELD_TYPE[connector]}
                            className="form-control"
                          >
                            {FIELD_TYPE[connector]}
                          </option>
                        )
                      })}
                    </select>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please select an option.
                      </div>
                    )}
                  </Col>

                  <Col classes="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="fname"
                      value={name}
                      onChange={e => {
                        setName(e.target.value)
                      }}
                      placeholder="Name"
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your full name.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="phone-number"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={e => {
                        setPhoneNumber(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your phone number.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter a valid email address.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="street-address"
                      placeholder="Street Address"
                      value={address}
                      onChange={e => {
                        setAddress(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter your complete address.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="city"
                      placeholder="City"
                      value={city}
                      onChange={e => {
                        setCity(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter city name.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="state"
                      placeholder="State"
                      value={state}
                      onChange={e => {
                        setState(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter state name.
                      </div>
                    )}
                  </Col>
                  <Col className="lg:col-span-2">
                    <input
                      className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                      type="text"
                      name="postal-code"
                      placeholder="Postal Code"
                      value={zip}
                      onChange={e => {
                        setZip(e.target.value)
                      }}
                      required
                    />
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Please enter postal code.
                      </div>
                    )}
                  </Col>
                  {FIELD_TYPE.show_track === contactReason && (
                    <Col classes="lg:col-span-2">
                      <input
                        className="border-2 rounded-3xl placeholder-black w-full h-12 pl-3"
                        type="text"
                        name="tracking"
                        placeholder="Tracking Number"
                        value={tracking}
                        onChange={e => {
                          setTracking(e.target.value)
                        }}
                        required
                      />
                      {showError && (
                        <div className="text-xs pl-4 text-red-500">
                          Can't be left Empty.
                        </div>
                      )}
                    </Col>
                  )}
                  <Col classes="lg:col-span-2">
                    <textarea
                      className="border-2 rounded-3xl placeholder-black p-3 mb-5 w-full"
                      rows="8"
                      name="message"
                      // cols="100"
                      placeholder="Leave us a detailed message..."
                      value={message}
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                      required
                    ></textarea>
                    {showError && (
                      <div className="text-xs pl-4 text-red-500">
                        Can't be left blank.
                      </div>
                    )}
                  </Col>
                </Grid>
                <Container padding="none" classes="py-6">
                  <center>
                    <button className="px-10 py-6 text-white font-bold lg:text-xl text-lg  rounded-full bg-accent-light hover:bg-accent hover:text-white transition duration-150 ease-in-out">
                      Submit Inquiry
                    </button>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      // newestOnTop={false}
                      cloSeonClick
                      rtl={false}
                      pauSeonFocusLoss
                      draggable
                      pauSeonHover
                    />
                  </center>
                </Container>
              </form>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default Contact
